import * as React from 'react';

interface IHealthState {
    health: number;
}
class Health extends React.Component<{}, IHealthState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            health: 1,
        };
    }

    public render(): React.ReactNode {
        return (
            <div>{this.state.health}</div>
        );
    }
}

export {Health as default};
